import { BaseIP } from "./CommonApi";

//=========Muhurt Type-1 API's=========
export const saveMuhurtType1= BaseIP+ "muhurt/transaction/saveMuhurtType1" // Save muhurt type1 (POST)
export const updateMuhurtType1= BaseIP+ "muhurt/transaction/updateMuhurtType1" // Update muhurt type1 (PUT)
export const deleteMuhurtType1= BaseIP+ "muhurt/transaction/deleteMuhurtType1" // Delete muhurt type1 (DELETE)  (?code=2)
export const getMuhurtType1ByCode= BaseIP+ "muhurt/transaction/getMuhurtType1ByCode" // Get Muhurt Type1 By Code (GET) (GET)(?code=2)
export const getMuhurtType1List= BaseIP+ "muhurt/transaction/getMuhurtType1List" // Get muhurt type1 list (GET)


//=========Muhurt Type-2 API's=========
export const saveMuhurtType2= BaseIP+ "muhurt/transaction/saveMuhurtType2" // Save muhurt type2 (POST)
export const updateMuhurtType2= BaseIP+ "muhurt/transaction/updateMuhurtType2" // Update muhurt type2 (PUT)
export const deleteMuhurtType2= BaseIP+ "muhurt/transaction/deleteMuhurtType2" // Delete muhurt type2 (DELETE)  (?code=2)
export const getMuhurtType2ByCode= BaseIP+ "muhurt/transaction/getMuhurtType2ByCode" // Get Muhurt Type2 By Code (GET) (GET)(?code=2)
export const getMuhurtType2List= BaseIP+ "muhurt/transaction/getMuhurtType2List" // Get muhurt type2 list (GET)