import React, { useState, useEffect, useCallback } from 'react';
import TitleLabel from '../../CommonComponents/TitleLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PasunPryntForm from './PasunPryntForm';
import FaktForm from './FaktForm';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import axios from 'axios';
import {  getMuhurtMasterList, } from '../Services/MasterApi';

const useStyles = makeStyles({
  caption: {
      padding: 0,
      fontWeight: 500,
      fontSize: "12px"
  },
  toolbar: {

      padding: "0px",
      margin: "0px"
  },
  menuItem: {
      fontSize: "12px",
      padding: "4px"
  },
  displayedRows: {
      fontSize: "12px",
      padding: "0px",
      margin: "-15px"
  },

  selectLabel: {
      fontSize: "12px",
      padding: "0px",
      margin: "0px -4px 0px 6px "
  },
  spacer: {
      padding: "0px",
      margin: "0px",
      flex: 'none'
  },
  input: {
      fontSize: "5px",
      padding: "0px",
      margin: "0px"
  },
  root: {
      padding: "0px",
      margin: "0px"
  }
});

const MDEMaster = () => {
  const titleName = 'Muhurta Data Entry';
  const [formType, setFormType] = useState('');
  const [apiData, setApiData] = useState([]);
  const authUser = useSelector((state) => state.user.value);
 
  
  const [selectedName, setSelectedName] = useState('');
  const [selectedMuhurtType, setSelectedMuhurtType] = useState('');
  const [selectedCodeId, setSelectedCodeId] = useState('');

  const formHandle = useCallback((item) => {
    setSelectedCodeId(item.id);
    setFormType(item.type);
    setSelectedName(item.muhurtName);
    setSelectedMuhurtType(item.type);
  }, []);

//============================================================================


  const data1=sessionStorage.getItem('token');
  const [token, setToken]=useState(null)

  useEffect(()=>{
    
    if(data1){
      setToken(data1)
    }
    else{
      setToken(null)
    }
},[])



//==========muhurt data list=======================

  useEffect(() => {
    axios.get(getMuhurtMasterList,{
      headers: {
        'token':data1
      }
    })
    .then((response) => {
      setApiData(response.data);
  })
      .catch((error) => console.error('Error fetching data:', error));
  }, []); 



  //=========================================================================
  return (
    <>
      <div className="container">
        <TitleLabel titleName={titleName}/>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '16px',
            boxShadow: '0px 2px 15px #CECECE',
            marginTop: '16px',
            marginBottom: '20px',
            boxSizing: 'border-box',
          }}
        >
          <div className="row">
            <div className="col-2">
              <div className="col-12 mdeSidebar1">
                {apiData.map((item, index) => (
                  <div
                    key={index}
                    className="mdes-btn"
                    onClick={() => formHandle(item)}
                  >
                    <RadioButtonCheckedIcon
                      fontSize="14px"
                      style={{ marginRight: '4px',color:"black" }}
                    />
                    {item.muhurtName}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-10">
              <div className="col-12 mdeSidebar">
                {formType === 1 ? <FaktForm  dynamicName={selectedName} dynamicMuhurtType={selectedMuhurtType}  muhurtId={selectedCodeId}/> : <PasunPryntForm dynamicName={selectedName} dynamicMuhurtType={selectedMuhurtType} muhurtId={selectedCodeId}/>}

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default MDEMaster;
