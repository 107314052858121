import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from './reduxFeature/user';

import Login from './Login';
import MainPage from './components/MainPage'

function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isUser, setIsUser] = useState(false);


  useEffect(() => {

    const getData = sessionStorage.getItem("techno-muhrta");
    if (getData) {
        //console.log(JSON.parse(getData))
        const localUserData = (JSON.parse(getData));

        dispatch(login({ roleId: localUserData.roleId, userName: localUserData.userName, }));
        setIsUser(true);
        //navigate("/dashboard/");

    }
}, []);

  return (
    (!isUser) ? <Login setIsUser={setIsUser} /> : <MainPage setIsUser={setIsUser} />
  );
}

export default App;
