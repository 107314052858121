import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TitleLabel from '../CommonComponents/TitleLabel';
import { getCityMasterList1, getCountryMasterList1,getModelMasterList1,getPartyMasterList1, getPatrikaEntryDelivered, getPatrikaEntryDeliveredSave, getWeekDaysMasterList1, savePatrikaEntry, updatePatrikaEntry } from './Services/PatrikaEntryApi';
import { toast, ToastContainer } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const PatrikaDeliverForm = () => {    

    const titleName = "दिलेली पत्रिका";
    const navigate = useNavigate();
    const data1=sessionStorage.getItem('token');
    const [token, setToken]=useState(null)

    useEffect(()=>{
      
      if(data1){
        setToken(data1)
      }
      else{
        setToken(null)
      }
  },[])



const [regNos, setRegNos] = useState([]);
const [date, setDate] = useState('');
const [regNo, setRegNo] = useState('');

//==========================================================================
//  const parts =date ? date.split('/'):[];
//  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

//=======================================================================

  //============================weekDay यादी get api code====================================================

const getRegNoData = () =>{

    axios
    .get(getPatrikaEntryDelivered, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
        setRegNos(res.data);
        if(res.data == 'UNAUTHORIZED'){
            toast.warn('Your data is UNAUTHORIZED!!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            sessionStorage.clear();
            navigate("/login"); 
          }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getRegNoData()
  },[])


//===============================================================================


const handleDate = (e) => {
    setDate(e.target.value);
};

 const handleRegNo = (e) =>{
    setRegNo(e.target.value)
  }

  
//====================save data code===================

const SaveData = (e) =>{
    e.preventDefault();
  if(date !== "" && regNo !== ""){

    axios
    .post(`${getPatrikaEntryDeliveredSave}?regNo=${regNo}&date=${date}`,{
        headers: {
          'token':data1 
        }
      })
    .then((response) => {
      if (response.data == "SAVED") {
        toast.success("Data Saved Successfully!!!",{
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,     
            pauseOnHover: true,
            draggable: true,
          });

        navigate("/pt001/")

      } else if (response.data == "ALREADYEXISTS") {
        toast.warn("Data Already Exist!!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }else if(response.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
       else {
        toast.error("Please Select All Fields !!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('Catch Block:' + err.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  }
  else{
    alert("Please fill out all fields..!");
  }
  }; 

  //=====================================================================================
    return (
        <>
            <div className="container">
                <TitleLabel titleName={titleName} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>
                    <form>


                        <div className="form-group row my-2">
                            <div className="col-12">
                                <div className="row mb-2">

                                    <div className="col-sm-1"></div>

                                    <div className="col-sm-5">
                                        {/* <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>रजि.नं. : </label> */}

                                        <Autocomplete
                                          disablePortal size='small'
                                          id="combo-box-demo"
                                          options={regNos.map((regNoItem) => (
                                            { value: regNoItem.regNo, label: `${regNoItem.regNo} - ${regNoItem.name}` }
                                          ))}
                                          value={regNo}
                                          onChange={(event, newValue) => handleRegNo(newValue)}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="रजि.नं. निवडा"
                                              required
                                              style={{ borderRadius: "5px", height: "38px" }}
                                            />
                                          )}
                                        />

                                    </div>

                                    
                                    <div className="col-sm-5">
                                        {/* <label htmlFor="inputName" className="col-sm-2 col-form-label" style={{ fontWeight: "600", fontSize: "14px", }}>तारीख : </label> */}

                                        <TextField
                                            className="form-control form-control-sm"
                                            required size='small'
                                            id="outlined-required"
                                            label="तारीख"
                                            type="date"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            value={date}
                                            onChange={handleDate}
                                          />
                                    </div>

                                    <div className="col-sm-1"></div>

                                </div>

                            </div>
                           
                        </div>

                        <div className="col-12 text-center">
                                <>
                                    <button type="submit" className="btn btn-sm saveBtn text-light" onClick={SaveData}>
                                        Save
                                    </button>
                                    <button type="button" onClick={() => navigate("/pt001/")} className="btn btn-sm mx-2 cancleBtn text-light">
                                        Cancel
                                    </button>
                                </>
                        </div>

                    </form>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default PatrikaDeliverForm