import React, { useState, useEffect } from 'react';
import TitleLabel from '../CommonComponents/TitleLabel';
import TablePagination from '@mui/material/TablePagination'
import { Link, useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { deletePatrikaEntry, getPatrikaEntryList } from './Services/PatrikaEntryApi';
import axios from 'axios';
import { getMuhurtType1List } from './Services/MuhurtDataEntryApi';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ArticleIcon from '@mui/icons-material/Article';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAsIcon from '@mui/icons-material/SaveAs';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 500,
        fontSize: "12px"
    },
    toolbar: {

        padding: "0px",
        margin: "0px"
    },
    menuItem: {
        fontSize: "12px",
        padding: "4px"
    },
    displayedRows: {
        fontSize: "12px",
        padding: "0px",
        margin: "-25px"
    },

    selectLabel: {
        fontSize: "12px",
        padding: "0px",
        margin: "0px -4px 0px 6px "
    },
    spacer: {
        padding: "0px",
        margin: "0px",
        flex: 'none'
    },
    input: {
        fontSize: "5px",
        padding: "0px",
        margin: "0px"
    },
    root: {
        padding: "0px",
        margin: "0px"
    }
});

const PatrikaEntryTable = () => {

  //======================================================================

  const navigate = useNavigate();

  const handleEdit = (item) => {
    navigate(`/pf001/`, { state: { data: item, isEditMode: true } });
};

  //======================================================================

    const titleName = "नोंदणी यादी";

    const [data, setData] = useState([]);
    const classes = useStyles();
    const authUser = useSelector((state) => state.user.value);
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [status, setStatus] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const data1=sessionStorage.getItem('token');

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const [token, setToken]=useState(null)

    useEffect(()=>{
      
      if(data1){
        setToken(data1)
      }
      else{
        setToken(null)
      }
  },[])


    const TableHeading = [
        { label: 'रजी. नं.', key: 'id' },
        { label: `नाव`, key: 'nameMr' },
        { label: `जन्मतारीख`, key: 'bdate' },
        { label: `जन्मवेळ`, key: 'btime' },
        { label: `वार`, key: 'day' },
        { label: `जन्मस्थळ`, key: 'bplace' },
        { label: `इतर माहिती`, key: 'info' },
        { label: `मोबाईल नं`, key: 'mob' },
        { label: `रक्कम`, key: 'amt' },
        { label: `आडवान्स`, key: 'adv' },
        { label: 'Status', key: 'status', value: 'status', isAction: true },
        { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
        { label: 'Delete', key: 'del', value: 'delete', isAction: true },
    ]

   
//=========get data code===============================================
const getPatrikaEntryData = () =>{

    axios
    .get(getPatrikaEntryList, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
      setData(res.data);
      setFilteredData(res.data);
      if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getPatrikaEntryData()
  },[])
  
 

    const [filteredData, setFilteredData] = useState(data)
    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }
    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    (item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.birthPlace?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.entryDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.genderName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.city?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.mobileNo?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.birthDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.status?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
                    (item.dayName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '')
            )
        );
    }, [searchTerm,data]);


  //=================delete data code==================

  //==CSS for delete modal=======
  const confirmationModal ={
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:" rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,  
  }

  const confirmationModal1 ={
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:" rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,  
  }
  
  const modalContent ={
    background:" #fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  }


  
  const modalButtons = {display:"flex", marginTop: "20px",  justifyContent:"center" }

  const Buttons1 = { color:"white", backgroundColor:"red",  border:"1px solid red",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

  const Buttons2 = { color:"black", backgroundColor:"#F1C40F",  border:"1px solid #F1C40F",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

  //=======================

  const statusSaveModal =()=>{
    setShowStatusModal(false);
    setStatus("");
  }
  const statusSaveModal1 =()=>{

  }

  const [showStatusModal, setShowStatusModal] = useState(false);
  const handleStatus=()=>{
    setShowStatusModal(true) 
  }

  const handleStatusChange = (e) =>{
    setStatus(e.target.value)
  }


  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDelete = (item) => {
    setItemToDelete(item);

    setShowConfirmationModal(true);
  };
  const closeConfirmationModal=()=>{
    setShowConfirmationModal(false);
  }

  const confirmDelete = () => {
    axios
      .delete(`${deletePatrikaEntry}?regNo=${itemToDelete.regNo}`, {
        headers: {
          'token': token
        }
      })
      .then((res) => {
        
        if (res.data === 'DELETED') {
            getPatrikaEntryData();
            closeConfirmationModal();
          toast.success('Entry deleted successfully!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const updatedData = data.filter((item) => item.regNo !== itemToDelete.regNo);
          setData(updatedData);
        } else if (res.data === 'ERROR') {
          toast.error('An error occurred during delete!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data === 'NOTFOUND') {
          toast.warn('Entry not found for delete!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        else if (res.data == 'UNAUTHORIZED'){
          toast.warn('Your data is UNAUTHORIZED!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.clear();
          navigate("/login"); 
        }

        setShowConfirmationModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };



//=====================================================================


    return (
        <>
            <div className="container">
                <TitleLabel titleName={titleName} />
                <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>

                    <div className='row'>
                        <div className='col-sm-8 my-1' style={{ float: "left" }} >
                            <button className='btn saveBtn btn-sm text-light me-2' onClick={()=>navigate("/pf001/")}> <PersonAddAltIcon fontSize='small' /> नवीन नाव नोंदणी</button>
                            <button className='btn saveBtn btn-sm text-light me-2' onClick={()=>navigate("/pf002/")}> <AddCircleOutlineIcon fontSize='small' /> तयार पत्रिका</button>
                            <button className='btn saveBtn btn-sm text-light me-2' onClick={()=>navigate("/pf003/")}> <SaveAsIcon fontSize='small' /> दिलेली पत्रिका</button>
                        </div>
                        <div className='col-sm-4 my-1' style={{ float: "right" }} >
                            <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                                    {TableHeading.map((item, index) => {
                                        return (
                                            <th
                                                style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={index}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        return (

                                         <>
                                         <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500", }}>{item.regNo}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.name}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.birthDate}</td>

                                                  <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                    {item.birthTimeFlag === 1
                                                        ? item.birthTime // Display birthTime when birthTimeFlag is 1
                                                        : item.timeFromToFlag === 1
                                                            ? `${item.timeFrom} - ${item.timeTo}` // Display timeFrom and timeTo when timeFromToFlag is 1
                                                            : ''}
                                                </td>

                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.dayName}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.birthPlace}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.otherData}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.mobileNo}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>Rs.{item.amount}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>Rs.{item.advanceAmount}</td>

                                                <td style={{ width: "50px" }}>
                                                
                                                <span className='mx-1 p-1' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                {item.status}
                                              </span>

                                                </td>
                                               
                                                <td style={{ width: "50px" }}>

                                                    <span className='mx-1 p-1'>
                                                        <ModeEditIcon fontSize='small' style={{ cursor: "pointer", color: "#4A78FF" }} onClick={() => handleEdit(item)} />
                                                    </span>
                                                

                                                </td>
                                                <td style={{ width: "50px" }}>

                                                    <span className='mx-1 p-1' data-bs-toggle="modal" data-bs-target="#exampleModal" >
                                                    <DeleteIcon fontSize='small' style={{ cursor: "pointer", color: "#CD2F44" }} onClick={() => handleDelete(item)} />
                                                    </span>

                                                </td>
                                            </tr>
                                         </>

                                        )
                                    })}
                            </tbody>
                  
                          {/* ========status change modal===== */}

                            {showStatusModal && (
                            <div className='row p-0' style={confirmationModal1}>
                              

                             <div className="col-4"></div>

                             <div className="col-4" style={{borderRadius:"5px", backgroundColor:'white',backgroundColor:"white",padding:"20px"}} >

                             <button onClick={statusSaveModal} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>X</button>

                             <label htmlFor="inputName" className="col-12 d-flex" style={{ fontWeight: "600", fontSize: "14px" }}>Select Status </label>
                                           <select
                                            className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={status}
                                            onChange={handleStatusChange}
                                            required
                                            style={{borderRadius:"5px",height:"38px",border:"1px solid"}}
                                            >
                                            <option value="">Select Status</option>
                                            <option value="1">Status 1</option>
                                            <option value="2">Status 2</option>
                                            <option value="3">Status 3</option>
                                           
                                        </select>

                                <div style={modalButtons}>
                                  <button onClick={statusSaveModal} style={Buttons1}>Cancel</button>
                                  <button onClick={statusSaveModal1} style={Buttons2}>Save</button>
                                </div>
                                
                             </div>

                             <div className="col-4"></div>
                             
                            </div>
                          )}



                          {/* =====delete confirmation modal========= */}
                            {showConfirmationModal && (
                            <div style={confirmationModal}>
                              <div style={modalContent}>
                                <p>Are you sure you want to delete this Entry?</p>
                                <div style={modalButtons}>
                                  <button onClick={confirmDelete} style={Buttons1}>Delete</button>
                                  <button onClick={closeConfirmationModal} style={Buttons2}>Cancel</button>
                                </div>
                              </div>
                            </div>
                          )}

                            <tfoot >
                                <tr style={{ }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default PatrikaEntryTable