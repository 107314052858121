import React, { useState, useEffect } from 'react';
import TitleLabel from '../../CommonComponents/TitleLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { deleteCityMaster, getCityMasterList, saveCityMaster, updateCityMaster } from '../Services/MasterApi';


const useStyles = makeStyles({
    caption: {
        padding: 0,
        fontWeight: 500,
        fontSize: "12px"
    },
    toolbar: {

        padding: "0px",
        margin: "0px"
    },
    menuItem: {
        fontSize: "12px",
        padding: "4px"
    },
    displayedRows: {
        fontSize: "12px",
        padding: "0px",
        margin: "-15px"
    },

    selectLabel: {
        fontSize: "12px",
        padding: "0px",
        margin: "0px -4px 0px 6px "
    },
    spacer: {
        padding: "0px",
        margin: "0px",
        flex: 'none'
    },
    input: {
        fontSize: "5px",
        padding: "0px",
        margin: "0px"
    },
    root: {
        padding: "0px",
        margin: "0px"
    }
});


const CityMaster = () => {

  const titleName = "CITY MASTER";

  const [data, setData] = useState([]);
  const [Id, setId] = useState()
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [isAscending, setAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [showUpdateButton,setShowUpdateButton]=useState(false)
  const [showSaveButton,setShowSaveButton]=useState(true)

  const data1=sessionStorage.getItem('token');
  
  const onPageChange = (event, nextPage) => {
      setPage(nextPage);
  }
  const onRowsPerPageChange = (e) => {
      setRowsPerPage(parseInt(e.target.value));
      setPage(0);
  }

  const [token, setToken]=useState(null)

  useEffect(()=>{
    
    if(data1){
      setToken(data1)
    }
    else{
      setToken(null)
    }
},[])

  const TableHeading = [
      { label: 'अनु. क्र.', key: 'id' },
      { label: `शहर नाव`, key: 'nameMr' },
      { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
      { label: 'Delete', key: 'del', value: 'delete', isAction: true }
  ]


  const [name, setName] = useState("");


  const cancleEntry = () => {
      setName("");
    }
  const cancleEntry1 = () => {
      cancleEntry();
      setShowSaveButton(true);
      setShowUpdateButton(false);
    }

//=========get data code============
const getCityData = () =>{

  axios
  .get(getCityMasterList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
    setData(res.data);
    setFilteredData(res.data);
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getCityData()
},[])



  const [filteredData, setFilteredData] = useState(data)
  const onSort = (key, isAction) => {
      if (!isAction) {
          setSortedBy(key)
          if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
          else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
          setAscending(!isAscending)
      }
  }
  useEffect(() => {
      setFilteredData(
          data.filter(
              (item) =>
                  item.cityName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
  }, [searchTerm]);


//============save data code===========

const saveFun = (e) =>{
  e.preventDefault();
  const saveObj = {
    cityName:name,
  }

  axios
  .post(saveCityMaster, saveObj,{
      headers: {
        'token':token 
      }
    })
  .then((response) => {
    if (response.data == "SAVED") {
      toast.success("City Saved Successfully!!!",{
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      cancleEntry();
      getCityData();

    } else if (response.data == "ALREADYEXISTS") {
      toast.warn("City Already Exist!!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error("Please Select All Fields !!!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  })
  .catch((err) => {
    console.log(err);
    toast.error('Catch Block:' + err.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  });
};

  //==========update data code=====================
  
const setEditDataFun = (item) =>{

  setId(item.id)
  setName(item.cityName)

  setShowUpdateButton(true);
  setShowSaveButton(false);

}

const handleUpdate = () =>{

      const updateObj ={
        id:Id,
        cityName:name,
      }

      
  axios
  .put(updateCityMaster,updateObj,{ 
          headers: {
            'token':token
          }
  })
  .then((res) =>{

    if(res.data==="UPDATED"){
      getCityData();
      cancleEntry();
      setShowUpdateButton(false);
      setShowSaveButton(true);

      toast.success('City Updated Successfully!!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    }
    else if(res.data==="ALREADYEXISTS"){
      toast.warn('City Already Exist!!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
  })
  .catch((err) =>{
    console.log(err);
  })
}

//=================delete data code==================
//==CSS for delete modal=======
const confirmationModal ={
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  background:" rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 999,
  
}

const modalContent ={
  background:" #fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
}

const modalButtons = {display:"flex", marginTop: "20px",  justifyContent:"center" }

const Buttons1 = { color:"white", backgroundColor:"red",  border:"1px solid red",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

const Buttons2 = { color:"black", backgroundColor:"#F1C40F",  border:"1px solid #F1C40F",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

//=======================
const [showConfirmationModal, setShowConfirmationModal] = useState(false);
const [itemToDelete, setItemToDelete] = useState(null);

const handleDelete = (item) => {
  setItemToDelete(item);

  setShowConfirmationModal(true);
};

const confirmDelete = () => {
  axios
    .delete(`${deleteCityMaster}?id=${itemToDelete.id}`, {
      headers: {
        'token': token
      }
    })
    .then((res) => {
      if (res.data === 'DELETED') {
        getCityData();
        toast.success('City deleted successfully!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        const updatedData = data.filter((item) => item.id !== itemToDelete.id);
        setData(updatedData);
      } else if (res.data === 'ERROR') {
        toast.error('An error occurred during delete!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else if (res.data === 'NOTFOUND') {
        toast.warn('City not found for delete!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      setShowConfirmationModal(false);
    })
    .catch((err) => {
      console.log(err);
    });
};

const closeConfirmationModal = () => {
  setShowConfirmationModal(false);
};


//=======================================


  return (
      <>
          <div className="container">
              <TitleLabel titleName={titleName} />
              <div style={{ backgroundColor: "#fff", padding: "16px", boxShadow: "0px 2px 15px #CECECE", marginTop: "16px", marginBottom: "20px" }}>
                  <form>
                      <div className="form-group col-sm-6 my-2">
                          <div className="row">
                              <label htmlFor="inputName" className="col-sm-3 col-form-label" style={{ fontWeight: "600", fontSize: "14px" }}>शहर नाव : </label>
                              <div className="col-sm-7">
                                  <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control form-control-sm" id="inputName" placeholder="शहर नाव" />
                              </div>

                          </div>
                      </div>


                      <div className="form-group col-sm-6 my-2">
                          <div className="row">
                              <div className="col-sm-3"></div>
                              <div className="col-sm-6">

                                 {showSaveButton && (
                                    <>
                                    <button type="submit" className="btn btn-sm saveBtn text-light" onClick={saveFun}>Save</button>

                                      <button type="submit" className="btn btn-sm mx-2 cancleBtn text-light" onClick={cancleEntry}>Cancel</button>
                                    </>
                                  )}

                                  {showUpdateButton && (
                                       <>
                                       <button className="btn btn-sm mx-2 text-light" type='submit' style={{color:"black",backgroundColor:"orangered",border:"1px solid orangered"}} onClick={() => handleUpdate()}>Update</button>

                                      <button type="submit" className="btn btn-sm mx-2 cancleBtn text-light" onClick={cancleEntry1}>Cancel</button>
                                       </>

                                       )}
                                  
                              </div>
                          </div>
                      </div>
                  </form>
                  <hr />
                  <div className='row'>
                      <div >
                          <input
                              className='form-control form-control-sm'
                              type="text"
                              style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                              placeholder="Search Here"
                              value={searchTerm}
                              onChange={(event) => { setSearchTerm(event.target.value); }}
                          />
                      </div>
                  </div>
                  <div className="table-responsive mt-2">

                      <table className="table table-bordered">
                          <thead className="table-Default">
                              <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                                  {TableHeading.map((item, ind) => {
                                      return (
                                          <th
                                              style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                              onClick={() => onSort(item.key, item.isAction)}
                                              key={ind}
                                              scope="col"
                                          >
                                              {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                          </th>
                                      )
                                  })}
                              </tr>
                          </thead>
                          <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                              {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((item, index) => {
                                      return (
                                          <tr key={index}>
                                              <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.id}</td>
                                              <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.cityName}</td>
                                              {/* <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.type == 1 ? 'फक्त मुहूर्त' : 'मुहूर्त पासून पर्यंत'}</td> */}
                                              <td style={{ width: "80px" }}>
                                                  <span className='mx-1 p-1' data-bs-toggle="tooltip">
                                                      <ModeEditIcon fontSize='small' style={{ cursor: "pointer", color: "#4A78FF" }}  onClick={() => setEditDataFun(item)}/>
                                                  </span>
                                              </td>
                                              <td style={{ width: "80px" }}>
                                                  <span className='mx-1 p-1' data-bs-toggle="modal" data-bs-target="#exampleModal" >
                                                      <DeleteIcon fontSize='small' style={{ cursor: "pointer", color: "#CD2F44" }} onClick={() => handleDelete(item)}/>
                                                  </span>
                                              </td>
                                          </tr>
                                      )
                                  })}
                          </tbody>

                          {showConfirmationModal && (
                          <div style={confirmationModal}>
                            <div style={modalContent}>
                              <p>Are you sure you want to delete this City?</p>
                              <div style={modalButtons}>
                                <button onClick={confirmDelete} style={Buttons1}>Delete</button>
                                <button onClick={closeConfirmationModal} style={Buttons2}>Cancel</button>
                              </div>
                            </div>
                          </div>
                        )}

                          <tfoot >
                              <tr style={{ padding: "4px" }}>
                                  <TablePagination
                                      style={{ padding: 0, borderBottom: "0px" }}
                                      rowsPerPageOptions={[10, 25, 50, 100]}
                                      labelRowsPerPage=" Rows : "
                                      count={data.length}
                                      rowsPerPage={rowsPerPage}
                                      page={page}
                                      onRowsPerPageChange={onRowsPerPageChange}
                                      onPageChange={onPageChange}
                                      classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                                  />
                              </tr>
                          </tfoot>
                      </table>                        
                  </div>
                  
              </div>
          </div>
          <ToastContainer theme='colored' />
      </>
  )
}


export default CityMaster