import React from 'react';
import MasterHeader from '../BaseComponents/MasterHeader';
import MasterContent from '../BaseComponents/MasterContent';
import MasterFooter from '../BaseComponents/MasterFooter';
const MainPage = ({ setIsUser }) => {
  const contentBg = {
    backgroundColor: "black",
    minHeight:"100vh"
  }
  return (
    <>
      <div style={contentBg}>
        <MasterHeader setIsUser={setIsUser} />
        <MasterContent />
        <MasterFooter />
      </div>
    </>
  )
}

export default MainPage