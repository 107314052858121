import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import {  getMarathiMonthList, getRashiMasterList, getTimeTypeMasterList, getTypeMasterList, getWeekDaysMasterList, getYearMasterList } from '../Services/MasterApi'
import {  deleteMuhurtType1, getMuhurtType1List, saveMuhurtType1, updateMuhurtType1 } from '../Services/MuhurtDataEntryApi'
import { useNavigate } from 'react-router-dom';
import TitleLabel from '../../CommonComponents/TitleLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PasunPryntForm from './PasunPryntForm';
import makeStyles from "@mui/styles/makeStyles";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSelector } from "react-redux";
import { deleteMuhurtMaster, getMuhurtMasterList, updateMuhurtMaster } from '../Services/MasterApi';
import { TablePagination } from '@mui/material';

const useStyles = makeStyles({
  caption: {
      padding: 0,
      fontWeight: 500,
      fontSize: "12px"
  },
  toolbar: {

      padding: "0px",
      margin: "0px"
  },
  menuItem: {
      fontSize: "12px",
      padding: "4px"
  },
  displayedRows: {
      fontSize: "12px",
      padding: "0px",
      margin: "-15px"
  },

  selectLabel: {
      fontSize: "12px",
      padding: "0px",
      margin: "0px -4px 0px 6px "
  },
  spacer: {
      padding: "0px",
      margin: "0px",
      flex: 'none'
  },
  input: {
      fontSize: "5px",
      padding: "0px",
      margin: "0px"
  },
  root: {
      padding: "0px",
      margin: "0px"
  }
});

const FaktForm = ({ dynamicName, muhurtId  }) => {

  //===============================================================
const [showUpdateButton,setShowUpdateButton]=useState(false)
const [showSaveButton,setShowSaveButton]=useState(true)
const [data, setData] = useState([]);
const [shriShakes, setShriShakes] = useState([]);
const [weekDays, setWeekDays] = useState([]);
const [timing, setTiming] = useState([]);
const [rashies, setRashies] = useState([]);
const [mities1, setMities1] = useState([]);
const [mities2, setMities2] = useState([]);
const [rashi, setRashi] = useState('');
const [timeType, setTimeType] = useState('');
const [date, setDate] = useState('');
const [time, setTime] = useState('');
const [weekDay, setWeekDay] = useState('');
const [miti1, setMiti1] = useState('');
const [miti2, setMiti2] = useState('');
const [dateType, setDateType] = useState('');
const [shriShake, setShriShake] = useState('');


const [formType, setFormType] = useState('');
const [apiData, setApiData] = useState([]);

  const [Id, setId] = useState('')
  const classes = useStyles();
  const authUser = useSelector((state) => state.user.value);
  const [sortedBy, setSortedBy] = useState(null);
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [showPasunPryntForm,setShowPasunPryntForm]=useState(false)
  const [showFaktForm,setShowFaktForm]=useState(true)

const [selectedName, setSelectedName] = useState('');
const [selectedMuhurtType, setSelectedMuhurtType] = useState('');


  const setFaktFormValues = (item) => {

      setId(item.code);
      setShriShake(item.sahke);
      setDateType(item.dateType);
      setMiti1(item.miti1);
      setMiti2(item.miti2);
      setWeekDay(item.weekDay);

      const timeParts = item.time.split(' ');
      const formattedTime = timeParts[0];
      setTime(formattedTime);

      const formattedDate = item.date.split('/').reverse().join('-');
      setDate(formattedDate);

      setTimeType(item.timeType);
      setRashi(item.rashi);

      setShowUpdateButton(true)
      setShowSaveButton(false);
    };

//==========update data code=====================
    
const UpdateData = () =>{
  
  const updateObj ={
    code:Id,
    muhurtTypeCode:muhurtId,
    sahke:shriShake,
    miti1:miti1,
    miti2:miti2,
    dateType:dateType,
    weekDay:weekDay,
    rashi:rashi,
    date:date,
    timeType:timeType,
    time:time,
  }
  
axios
.put(updateMuhurtType1,updateObj,{ 
      headers: {
        'token':data1
      }
})
.then((res) =>{

if(res.data==="UPDATED"){
  getMuhurtData();
  ClearData();
  setShowUpdateButton(false);
  setShowSaveButton(true);

  toast.success('Muhurt Updated Successfully!!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

}
else if(res.data==="ALREADYEXISTS"){
  toast.warn('Muhurt Already Exist!!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
}
else if(res.data == 'UNAUTHORIZED'){
  toast.warn('Your data is UNAUTHORIZED!!', {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  sessionStorage.clear();
  navigate("/login"); 
}
})
.catch((err) =>{
console.log(err);
})
}

  //================================================================

  const navigate = useNavigate();

  const data1=sessionStorage.getItem('token');
  const [token, setToken]=useState(null)

  useEffect(()=>{
    
    if(data1){
      setToken(data1)
    }
    else{
      setToken(null)
    }
},[])


const onPageChange = (event, nextPage) => {
  setPage(nextPage);
}
const onRowsPerPageChange = (e) => {
  setRowsPerPage(parseInt(e.target.value));
  setPage(0);
}


  //============================श्रीशके यादी get api code=====================================================

  const getShriShakeData = () =>{

    axios
    .get(getYearMasterList, {
        headers: {
            'token':data1
          }
    })
    .then((res) => {
      setShriShakes(res.data);
      if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
    })
    .catch((err) => console.log(err))
  }
  useEffect(() =>{
    getShriShakeData()
  },[])
     //============================राशी नाव यादी get api code====================================================

const getRashiData = () =>{

  axios
  .get(getRashiMasterList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
    setRashies(res.data);
    if(res.data == 'UNAUTHORIZED'){
      toast.warn('Your data is UNAUTHORIZED!!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      sessionStorage.clear();
      navigate("/login"); 
    }
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getRashiData()
},[])

 //============================वेळ यादी get api code (सकाळ,दुपार)================================================

const getTimeData = () =>{

  axios
  .get(getTimeTypeMasterList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
      setTiming(res.data);
      if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getTimeData()
},[])

 //============================दिवस यादी get api code====================================================

const getWeekDayData = () =>{

  axios
  .get(getWeekDaysMasterList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
      setWeekDays(res.data);
      if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getWeekDayData()
},[])

 //============================मराठी महिने यादी get api code====================================================

 const getMiti1Data = () =>{

  axios
  .get(getMarathiMonthList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
    setMities1(res.data);
    if(res.data == 'UNAUTHORIZED'){
      toast.warn('Your data is UNAUTHORIZED!!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      sessionStorage.clear();
      navigate("/login"); 
    }
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getMiti1Data()
},[])

//=========miti 2===(शुक्ल,कृष्ण)===========
const getMiti2Data = () =>{

  axios
  .get(getTypeMasterList, {
      headers: {
          'token':data1
        }
  })
  .then((res) => {
    setMities2(res.data);
    if(res.data == 'UNAUTHORIZED'){
      toast.warn('Your data is UNAUTHORIZED!!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      sessionStorage.clear();
      navigate("/login"); 
    }
  })
  .catch((err) => console.log(err))
}
useEffect(() =>{
  getMiti2Data()
},[])


//============================================================================

const TableHeadingTable = [
  { label: 'अनु. क्र.', key: 'id' },
  { label: 'दिनांक', key: 'date' },
  { label: 'वार', key: 'day' },
  { label: 'राशी', key: 'rashi1' },
  { label: 'वेळ', key: 'fromTimeType' },
  { label: 'वेळ', key: 'toTime',icon: 'far fa-clock' },
  { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
  { label: 'Delete', key: 'del', value: 'delete', isAction: true }
]

  //===============================================================================

const handleRashi = (e) =>{
  setRashi(e.target.value)
}
const handleTimeType = (e) =>{
  setTimeType(e.target.value)
}
const handleWeekDay = (e) =>{
  setWeekDay(e.target.value)
}

const handleTime = (e) =>{
  setTime(e.target.value)
}

const handleMiti1 = (e) =>{
  setMiti1(e.target.value)
}

const handleMiti2 = (e) =>{
  setMiti2(e.target.value)
}

const handleDateType = (e) =>{
  setDateType(e.target.value)
}

const handleDate = (e) =>{
  setDate(e.target.value)
}

const handleShriShake = (e) =>{
  setShriShake(e.target.value)
}


const ClearData=()=>{
  setShriShake('');
  setRashi('');
  setDate('');
  setTimeType('');
  setDateType('');
  setTime('');
  setMiti2('');
  setMiti1('');
  setWeekDay('');

  setShowUpdateButton(false);
  setShowSaveButton(true);
}
  //========save data code==============
  const SaveData = (e) =>{
    e.preventDefault();

    const saveObj = {
      muhurtTypeCode:muhurtId,
      sahke:shriShake,
      miti1:miti1,
      miti2:miti2,
      dateType:dateType,
      weekDay:weekDay,
      rashi:rashi,
      date:date,
      timeType:timeType,
      time:time,
    }

    axios
    .post(saveMuhurtType1, saveObj,{
        headers: {
          'token':token 
        }
      })
    .then((response) => {
      if (response.data == "SAVED") {
        toast.success("Muhurt Saved Successfully!!!",{
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,     
            pauseOnHover: true,
            draggable: true,
          });

          getMuhurtData();
          ClearData();
        // navigate("/pt001/")

      } else if (response.data == "ALREADYEXISTS") {
        toast.warn("Muhurt Already Exist!!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }else if(response.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      } else {
        toast.error("Please Select All Fields !!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('Catch Block:' + err.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  };

  //======================================================
 //=========get data code============
const getMuhurtData = () => {
  axios
    .get(`${getMuhurtType1List}?muhurtCode=${muhurtId}`, {
      headers: {
        'token': data1
      }
    })
    .then((res) => {
      setData(res.data);
      setFilteredData(res.data);
      if(res.data == 'UNAUTHORIZED'){
        toast.warn('Your data is UNAUTHORIZED!!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        sessionStorage.clear();
        navigate("/login"); 
      }
    })
    .catch((err) => console.log(err));
};

useEffect(() => {
  getMuhurtData();
}, [muhurtId]);

//==========================================================
const [filteredData, setFilteredData] = useState([]);
const [isAscending, setAscending] = useState(true);

const onSort = (key, isAction) => {
  if (!isAction) {
    setSortedBy(key);
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => (isAscending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
    setFilteredData(sortedData);
    setAscending(!isAscending);
  }
};

useEffect(() => {
  setFilteredData(
    data.filter(  
      (item) =>
        (item.rashiName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (item.weekDayName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (item.timeTypeName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (item.code?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (item.date?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '')
    )
  );
}, [searchTerm, data]);

//======================delete code=========================
  //==CSS for delete modal=======
  const confirmationModal ={
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background:" rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
    
  }
  
  const modalContent ={
    background:" #fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  }
  
  const modalButtons = {display:"flex", marginTop: "20px",  justifyContent:"center" }

  const Buttons1 = { color:"white", backgroundColor:"red",  border:"1px solid red",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

  const Buttons2 = { color:"black", backgroundColor:"#F1C40F",  border:"1px solid #F1C40F",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }

  const tableButtons = { color:"white", backgroundColor:"#FF3CAC",backgroundImage:"linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)",  border:"1px solid #FF3CAC",borderRadius:"5px",  padding:"5px 15px",margin:"0px 10px", }


  //=======================
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDelete = (item) => {
    setItemToDelete(item);

    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    axios
      .delete(`${deleteMuhurtType1}?code=${itemToDelete.code}`, {
        headers: {
          'token': data1
        }
      })
      .then((res) => {
        if (res.data === 'DELETED') {
          getMuhurtData();
          toast.success('Data deleted successfully!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const updatedData = data.filter((item) => item.code !== itemToDelete.code);
          setData(updatedData);
        } else if (res.data === 'ERROR') {
          toast.error('An error occurred during delete!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (res.data === 'NOTFOUND') {
          toast.warn('Data not found for delete!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
       else if(res.data == 'UNAUTHORIZED'){
          toast.warn('Your data is UNAUTHORIZED!!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.clear();
          navigate("/login"); 
        }

        setShowConfirmationModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };


  //=================================================================

  return (
    <>
      <div className="container-fluid p-2">
        <div
          style={{
            borderBottom: '1px solid #D5D8DC',
            display: 'flex',
            color: '#A93226',
          }}
        >
          <h6 className="fw-bold">{dynamicName}</h6>
        </div>

        <div>
          <div className="row p-2">
            <div
              className="col-md-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
                                      <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={shriShake}
                                            onChange={handleShriShake}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>श्रीशके  निवडा</option>
                                            {shriShakes.map((shriShakesItem) => (
                                            <option key={shriShakesItem.id} value={shriShakesItem.id}>
                                                {shriShakesItem.year}
                                            </option>
                                            ))}
                                        </select>
            </div>

            <div
              className="col-md-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
                                      <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                           value={miti1} onChange={handleMiti1}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>मिती निवडा</option>
                                            {mities1.map((mities1Item) => (
                                            <option key={mities1Item.id} value={mities1Item.id}>
                                                {mities1Item.nameMr}
                                            </option>
                                            ))}
                                        </select>
            </div>

            <div
              className="col-md-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
                                        <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                           value={miti2} onChange={handleMiti2}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>मिती निवडा</option>
                                            {mities2.map((mities2Item) => (
                                            <option key={mities2Item.id} value={mities2Item.id}>
                                                {mities2Item.typeName}
                                            </option>
                                            ))}
                                        </select>
            </div>

            <div
              className="col-md-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              ||
              <select
                class="form-select form-select-sm"
                aria-label="Default select example"
                style={{ width: '80%' }} value={dateType} onChange={handleDateType}
              >
                <option value="" disabled>निवडा</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
              </select>
              ||
            </div>
          </div>

          <div class="mb-3 row">
            <label for="staticEmail" class="col-sm-1 col-form-label">
              दिनांक:
            </label>
            <div class="col-sm-3">
              <input type="date" value={date} onChange={handleDate} class="form-control" id="date" />
            </div>

            <label for="staticEmail" class="col-sm-1 col-form-label">
              वार:
            </label>

            <div
              className="col-sm-3"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
                                      <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={weekDay}
                                            onChange={handleWeekDay}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>वार निवडा</option>
                                            {weekDays.map((weekDayItem) => (
                                            <option key={weekDayItem.id} value={weekDayItem.id}>
                                                {weekDayItem.weekDay}
                                            </option>
                                            ))}
                                        </select>
            </div>
          </div>

          <div
            style={{
              padding: '8px',
              backgroundColor: '#FFEAEA',
              borderRadius: '8px',
              border: '1px solid #EC5151',
            }}
          >
            <div class="row">
              <label for="staticEmail" class="col-sm-1 col-form-label">
                राशी:
              </label>
              <div
                className="col-md-2 p-0"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                                        <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={rashi}
                                            onChange={handleRashi}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>राशी निवडा</option>
                                            {rashies.map((rashiesItem) => (
                                            <option key={rashiesItem.id} value={rashiesItem.id}>
                                                {rashiesItem.rashiName}
                                            </option>
                                            ))}
                                        </select>
              </div>

              <label for="staticEmail" class="col-sm-1 col-form-label">
                वेळ:
              </label>
              <div
                className="col-md-2 p-0"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                                       <select
                                           className="form-select form-select-sm" aria-label=".form-select-sm example"
                                            value={timeType}
                                            onChange={handleTimeType}
                                            required
                                            style={{borderRadius:"5px",height:"38px"}}>
                                            <option value="" disabled>वेळ निवडा</option>
                                            {timing.map((timingItem) => (
                                            <option key={timingItem.id} value={timingItem.id}>
                                                {timingItem.timeType}
                                            </option>
                                            ))}
                                        </select>
              </div>

              <label for="staticEmail" class="col-sm-2 col-form-label">
                वेळ निवडा:
              </label>
              <div
                className="col-md-3 p-0"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <input type="time" value={time} onChange={handleTime}/>
              </div>
            </div>
          </div>

          <div className="mt-3 row">

          {showSaveButton && (
            <div>
              <button className="btn btn-sm saveBtn text-light" onClick={SaveData}>SAVE</button>
              <button className="btn btn-sm cancleBtn text-light mx-2" onClick={ClearData}>
                CANCEL
              </button>
            </div>
          )}

          {showUpdateButton && (
            <div>
              <button className="btn-sm updateBtn"  onClick={UpdateData}>UPDATE</button>
              <button className="btn btn-sm cancleBtn text-light mx-2" onClick={ClearData}>
                CANCEL
              </button>
            </div>
          )}
          </div>

          {/* Table */}

          <hr />
        </div>

        <div className="row">
                    <div className='row'>
                          <div >
                              <input
                                className='form-control form-control-sm'
                                type="text"
                                style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                                placeholder="Search Here"
                                value={searchTerm}
                                onChange={(event) => { setSearchTerm(event.target.value); }}
                            />
                        </div>
                    </div>

                    <div className="table-responsive mt-2">

                        <table className="table table-bordered">
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#EDC3FF", backgroundImage: "linear-gradient(200deg, #EDC3FF 10%, #aacaef 100%)" }}>
                                    {TableHeadingTable.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "14px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "15px", fontWeight: "500", }}>{index+1}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.date}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.weekDayName}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.rashiName}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500", }}>{item.timeTypeName}</td>
                                                <td style={{ fontSize: "15px", fontWeight: "500" }}>{item.time}</td>
                                                <td style={{ width: "80px" }}>
                                                    <span className='mx-1 p-1' data-bs-toggle="tooltip">
                                                      <ModeEditIcon fontSize='small' style={{ cursor: "pointer", color: "#4A78FF" }}  onClick={() => setFaktFormValues(item)}/>
                                                    </span>
                                                  
                                                </td>
                                                <td style={{ width: "80px" }}>
                                                    <span className='mx-1 p-1' data-bs-toggle="modal" data-bs-target="#exampleModal" >
                                                        <DeleteIcon fontSize='small' style={{ cursor: "pointer", color: "#CD2F44" }} onClick={() => handleDelete(item)}/>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>

                            {showConfirmationModal && (
                            <div style={confirmationModal}>
                              <div style={modalContent}>
                                <p>Are you sure you want to delete this Muhurt?</p>
                                <div style={modalButtons}>
                                  <button onClick={confirmDelete} style={Buttons1}>Delete</button>
                                  <button onClick={closeConfirmationModal} style={Buttons2}>Cancel</button>
                                </div>
                              </div>
                            </div>
                          )}

                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>

                        </table>                        
                    </div>
        </div>

      </div>
      <ToastContainer theme='colored' />
    </>
  )
}


export default FaktForm
