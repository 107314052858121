import React from 'react'

const MasterFooter = () => {
  const footerStyle = {
    background: "#c31432",
    background: "-webkit-linear-gradient(to right, #240b36, #c31432)",
    background: "linear-gradient(to right, #240b36, #c31432)",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
  return (
    <>
      <div className='py-3'></div>
      <div style={footerStyle}>
        &copy; 2023-24 टेक्नो मुहूर्त | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default MasterFooter