import { BaseIP } from "./CommonApi";

//=========Muhurt Master API's=========
export const saveMuhurtMaster= BaseIP+ "muhurt/master/saveMuhurtMaster" // Save muhurt (POST)
export const updateMuhurtMaster= BaseIP+ "muhurt/master/updateMuhurtMaster" // Update muhurt (PUT)
export const deleteMuhurtMaster= BaseIP+ "muhurt/master/deleteMuhurtMaster" // Delete muhurt (DELETE)  (?id=2)
export const getMuhurtMasterList= BaseIP+ "muhurt/master/getMuhurtMasterList" // Get muhurt list (GET)


//=========Party Master API's=========
export const savePartyMaster= BaseIP+ "muhurt/master/savePartyMaster" //Save Party Master (POST)
export const updatePartyMaster= BaseIP+ "muhurt/master/updatePartyMaster" //Update Party Master (PUT)
export const deletePartyMaster= BaseIP+ "muhurt/master/deletePartyMaster" //Delete Party Master (DELETE) (?id=2)
export const getPartyMasterList= BaseIP+ "muhurt/master/getPartyMasterList" //Get Party Master list (GET)


//=========Model Master API's=========
export const saveModelMaster= BaseIP+ "muhurt/master/saveModelMaster" //Save Model Master (POST)
export const updateModelMaster= BaseIP+ "muhurt/master/updateModelMaster" //Update Model Master (PUT)
export const deleteModelMaster= BaseIP+ "muhurt/master/deleteModelMaster" //Delete Model Master (DELETE) (?id=2)
export const getModelMasterList= BaseIP+ "muhurt/master/getModelMasterList" //Get Model Master list (GET)


//=========City Master API's=========
export const saveCityMaster= BaseIP+ "muhurt/master/saveCityMaster" //Save City Master (POST)
export const updateCityMaster= BaseIP+ "muhurt/master/updateCityMaster" //Update City Master (PUT)
export const deleteCityMaster= BaseIP+ "muhurt/master/deleteCityMaster" //Delete City Master (DELETE) (?id=2)
export const getCityMasterList= BaseIP+ "muhurt/master/getCityMasterList" //Get City Master list (GET)


//=========Country Master API's=========
export const saveCountryMaster= BaseIP+ "muhurt/master/saveCountryMaster" //Save Country Master (POST)
export const updateCountryMaster= BaseIP+ "muhurt/master/updateCountryMaster" //Update Country Master (PUT)
export const deleteCountryMaster= BaseIP+ "muhurt/master/deleteCountryMaster" //Delete Country Master (DELETE) (?id=2)
export const getCountryMasterList= BaseIP+ "muhurt/master/getCountryMasterList" //Get Country Master list (GET)


//=========WeekDays Master API's=========
export const saveWeekDaysMaster= BaseIP+ "muhurt/master/saveWeekDaysMaster" //Save WeekDays Master (POST)
export const updateWeekDaysMaster= BaseIP+ "muhurt/master/updateWeekDaysMaster" //Update WeekDays Master (PUT)
export const deleteWeekDaysMaster= BaseIP+ "muhurt/master/deleteWeekDaysMaster" //Delete WeekDays Master (DELETE) (?id=2)
export const getWeekDaysMasterList= BaseIP+ "muhurt/master/getWeekDaysMasterList" //Get WeekDays Master list (GET)


//=========TimeType Master API's=========
export const saveTimeTypeMaster= BaseIP+ "muhurt/master/saveTimeTypeMaster" //Save TimeType Master (POST)
export const updateTimeTypeMaster= BaseIP+ "muhurt/master/updateTimeTypeMaster" //Update TimeType Master (PUT)
export const deleteTimeTypeMaster= BaseIP+ "muhurt/master/deleteTimeTypeMaster" //Delete TimeType Master (DELETE) (?id=2)
export const getTimeTypeMasterList= BaseIP+ "muhurt/master/getTimeTypeMasterList" //Get TimeType Master list (GET)


//=========Rashi Master API's=========
export const saveRashiMaster= BaseIP+ "muhurt/master/saveRashiMaster" //Save Rashi Master (POST)
export const updateRashiMaster= BaseIP+ "muhurt/master/updateRashiMaster" //Update Rashi Master (PUT)
export const deleteRashiMaster= BaseIP+ "muhurt/master/deleteRashiMaster" //Delete Rashi Master (DELETE) (?id=2)
export const getRashiMasterList= BaseIP+ "muhurt/master/getRashiMasterList" //Get Rashi Master list (GET)


//=========Year Master API's=========
export const saveYearMaster= BaseIP+ "muhurt/master/saveYearMaster" //Save Year Master (POST)
export const updateYearMaster= BaseIP+ "muhurt/master/updateYearMaster" //Update Year Master (PUT)
export const deleteYearMaster= BaseIP+ "muhurt/master/deleteYearMaster" //Delete Year Master (DELETE) (?id=2)
export const getYearMasterList= BaseIP+ "muhurt/master/getYearMasterList" //Get Year Master list (GET)


//=========Type Master API's=========
export const saveTypeMaster= BaseIP+ "muhurt/master/saveTypeMaster" //Save Type Master (POST)
export const updateTypeMaster= BaseIP+ "muhurt/master/updateTypeMaster" //Update Type Master (PUT)
export const deleteTypeMaster= BaseIP+ "muhurt/master/deleteTypeMaster" //Delete Type Master (DELETE) (?id=2)
export const getTypeMasterList= BaseIP+ "muhurt/master/getTypeMasterList" //Get Type Master list (GET)


//=========Marathi Month API's=========
export const saveMarathiMonth= BaseIP+ "muhurt/master/saveMarathiMonth" //Save Marathi Month (POST)
export const updateMarathiMonth= BaseIP+ "muhurt/master/updateMarathiMonth" //Update Marathi Month (PUT)
export const deleteMarathiMonth= BaseIP+ "muhurt/master/deleteMarathiMonth" //Delete Marathi Month (DELETE)  (?id=2)
export const getMarathiMonthList= BaseIP+ "muhurt/master/getMarathiMonthList" //Get Marathi Month list (GET)


//=========Dashboard API's=========
export const getDashboardList= BaseIP+ "muhurt/dashboard/getDashboardCount" //Get dashboard muhurt data list (GET)
export const getDashboardReport1= BaseIP+ "muhurt/report/getMuhurtType1Report" //Get dashboard report 1(get)
export const getDashboardReport2= BaseIP+ "muhurt/report/getMuhurtType2Report" //Get dashboard report 2(get)
