import React from 'react'

const TitleLabel = ({titleName}) => {
    return (
        <>
            <div className="title align-middle my-3">
                <span className="vr p2" style={{ backgroundColor: '#790B39', width: '5px', opacity: '70%', fontSize: '25px', }}> </span>
                <span style={{ width: "100%", fontSize: "1.2rem", marginLeft:"4px", fontWeight:"500",color:"white" }}>{titleName}</span>
            </div>
        </>
    )
}

export default TitleLabel;
